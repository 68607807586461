%gradient-color {
    display: flex;
    align-content: center;
    padding: 1rem;
    border-width: 2px;
    border-style: solid;
    border-image:
        linear-gradient(to bottom, rgb(232, 9, 176) 0%, #6709e2 100%) 1 100%;
    border-left: .35rem solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
}

%dropdown-item-background {
    color: #6709e2;
    font-size: 0.875rem;
    width: 100%;
    height: 1.875rem;
    background-color: transparent;
    border: none !important;
    border-bottom: 0.125rem solid rgba(255, 255, 255, 0.25);
    background-clip: padding-box;
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c3c3c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
}

.continue-conversation-tile {
    margin-bottom: 0;
    background: linear-gradient(to right, #131212 0, rgb(68, 68, 119) 50%, rgba(94, 0, 53, 136) 100%);
    color: white;
}

.continue-conversation-header {
    @extend %gradient-color;
    color: white;
    text-transform: uppercase;
}

.continue-conversation-description {
    @extend %gradient-color;
    color: white;
    margin-top: 10%;
}

.continue-conversation-form-header {
    @extend %gradient-color;
}

.continue-conversation-form {
    margin-top: 2%;
}

.continue-conversation-form-item {
    @extend %dropdown-item-background;
}

.continue-conversation-form-item-dropdown {
    @extend %dropdown-item-background;

    -webkit-appearance: none;
    // background: url(/dropdown.svg) 98% 75% no-repeat !important;
}

.continue-conversation-form-item-btn {
    color: #fff;
    background-color: #2E2EFE;
    border-color: #2E2EFE;
    float: right;
    text-transform: uppercase;
}

.continue-conversation-form-item-comment {
    @extend %dropdown-item-background;
    width: 100%;
    height:100%;
}

.continue-conversation-form-submitted{
    text-align: center;
    margin-top: 15%;
}


.conversationModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.conversationModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.conversationModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.conversationModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}
// .continue-conversation-form-progress-item {
//     // display: inline;
// }

// .continue-conversation-form-progress-item-circle {
//     list-style-type: circle;
//     font-size: 2rem;
//     display: inline;

// }

// .continue-conversation-form-progress-item-dashed {
//     list-style-type: disc;
//     font-size: 2rem;
//     display: inline;

// }

// TODO
// take the gradient color command and extend all across