.service-double-item-top {
    // position: relative;
    // width: 100%;
    // height: 250px;
    display: flex;
}

.service-double-item-bottom {
    display: flex;
    // position: relative;
    // // top : 50%;
    // // bottom:0;
    // height: 250px;
}

.adm {
    max-height: 100px;
}

.main-service{
    max-height:500px;
}
.service-item-main {
    max-height: 100px !important;
}


// .service-item-1 {
//     position: relative;
//     height:50% !important;
// }
// .service-item-2 {
//     height: 10px;
// }

// .main-row{
//     height:300px;
// }

// .service-item-title {
//     max-height:20px !important;
// }

// .test-adn{
//     display: flex;
//     align-items: flex-end;
    
//     flex-wrap: wrap;
//     flex-shrink: inherit;
//     height:100px !important;
//     max-height:100% !important;
//     background:black;
// }
// .one{
//     height:50px !important;
//     max-height:50px !important;
//     flex-direction: row;

//     flex-wrap: wrap;
// }

.service-main-image{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height:100px !important;
    max-height:100px !important;
    flex-shrink: 0;
}