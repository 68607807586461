
.service-action-description  {
    color: #566066;
    margin-top: .1rem;
    font-size: 1.125rem;
    line-height: 1.61;
}

.service-action-description strong {
    font-weight: 700;
}
.service-action__container .flex-container{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}
.service-action__container .flex-container div{
    padding-right: 4%;
    padding-left: 4%;
    box-sizing: border-box;
    margin-top:.6rem;
    font-weight: 700;
    font-size: 1.8rem;  
}
.service-action__container .flex-container .title1{
    position: relative;
    animation-name: title1;
    animation-duration: 1s;
}
.service-action__container .flex-container .title2{
    position: relative;
    animation-name: title2;
    animation-duration: 2s;
    border-left: 3px solid;
    border-right: 3px solid;
    border-image: linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
}
.service-action__container .flex-container .title3{
    position: relative;
    animation-name: title3;
    animation-duration: 1s;
}
@keyframes title1 {
    0%   { left:-100%;}
    100% { left:0px;}
}

@keyframes title2 {
  0%   { opacity:0;}
  100% { opacity:1;}
}

  @keyframes title3 {
    0%   { left:100%;}
    100% { left:0px;}
  }


.service-action__title {
    margin-top:-3.6rem;
    font-weight: 700;
    line-height: 1.2;
    font-size: 2rem;
    line-height: 1.31;
}

.service-action__jumbotron {
    display: block;
    max-width: calc(1280px + 4rem);
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    background-color: white !important;
}

.service-action__container {
    
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative !important;
}
@media (max-width: 1023px) {
    .service-action__button {
        font-size: 0.875rem;
        height: 3rem;
        padding: 0 2.5rem;
    }
}

@media  (max-width: 775px) {
    .service-action__container .flex-container div{
        font-size: 2rem;
        
    }
    .service-action__container .flex-container{
        flex-direction: column;
        
    }
    .service-action__container .flex-container .title2{
        border: none;
        
    }
    .service-action__container .flex-container .title2::after{
        position: absolute; 
        left: 30%; 
        bottom: -8px; 
        width: 40%; 
        height: 1px; 
        border-top: 1px solid; 
        content: "";
        border: 2px solid transparent;
        -moz-border-image: -moz-linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
        -webkit-border-image: -webkit-linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
        border-image: linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
        border-image-slice: 1;
        }
    .service-action__container .flex-container .title1::after{

    position: absolute; 
    left: 30%; 
    bottom: -8px; 
    width: 40%; 
    height: 2px; 
    // border-bottom: 1px solid; 
    content: "";
    border: 2px solid transparent;
    -moz-border-image: -moz-linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
    -webkit-border-image: -webkit-linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
    border-image: linear-gradient(to bottom, #e709b0 0%, #6709e2 100%) 1 100%;
    border-image-slice: 1;
}
}
.service-action__button:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    border-radius: 3px;
    background-color: #111822;
    transition: all ease-in-out 250ms;
}
.service-action__button:before {
    background-color: #fff;
}


