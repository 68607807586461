.services-hero{
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 485px;
    color: #f0f0f0;
    // object-fit: cover;
}
.services-hero .display-3{
    font-size: 4vw;
}
.services-hero .lead{
    font-size: 17px;
    font-weight: lighter;
}


@media only screen and (max-width: 956px) {
    .services-hero .display-3{
        font-size: 7vw;
    }
    .services-hero .lead{
        font-size: 16px;
        font-weight: lighter;
    }
  }
  @media only screen and (max-width: 386px) {
    .services-hero .display-3{
        font-size: 7.4vw;
    }
    .services-hero .lead{
        font-size: 14px;
        font-weight: lighter;
    }
  }
