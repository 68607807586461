.searchbar{
color:#003460;
background-color: #003460;
}

.searchbar-icon{
    color: black ;
    cursor: pointer;
    font-size: 20px;
}
.searchbar-icon2{
    color: white ;
    cursor: pointer;
    font-size: 20px;
}
.searchDiv{
    position: relative;
}
.searchInnerDiv{
    border: none;
    height: 30px;
    z-index: 999;
    width: 237px;
    position: absolute;
    top: 175%;
    left: -500%;
}
