
.about-text{
    position: relative;
    animation-name: about-animate;
    animation-duration: 2s;
}
.about-text h3{
   font-weight: bolder;
   font-family: Arial, sans-serif;
   margin: 3.5% 0%;
   line-height: 32px;
}
.about-text ul{
    margin: 2% 3%;
    list-style: disc;
}
.about-text ul li{
    margin: 1%;
}

@keyframes about-animate {
    0%   {opacity: 0; bottom: -50px;}
    100% {opacity: 1; bottom: 0px;}
  }