.challenges-component {
    margin-top: 50px;
    margin-bottom: 50px;
}

// .challenge-card:hover challenge-learn-btn {
//     display: block;
// }

.challenge-card-a {
    color: inherit;
}

.challenge-card-a:hover {
    text-decoration: none;
    color: inherit;
}

// .challenges-image {
//     // height: 315px;
//     // width: 500px;
//     // display: block;
//     // width: 100%;
//     // object-fit: cover;

// }


.challenge-lean-more {
    margin-bottom: 25px;
}

.challenge-card-topic {
    text-align: left;
    margin-bottom: 3%;
}

.challenge-card-topic-link {
    font-family: "Graphik SemiBold", "Arial", Sans-Serif;
    font-size: .95rem;
    color: #000;
    border-bottom: .15rem solid;
    border-color: #004dff;
    padding-bottom: .25rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.challenge-card-title {
    text-align: left;
    font-weight: bold;
}

.challenge-card-description {
    text-align: left;
}
.challenges-component .card-list-header{
    font-size: 2.2rem;
} 