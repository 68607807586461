.readyToStart{
    width: 100%;
    height: 100%;
    background-image: url('./Slanted-Gradient.svg');
    background-repeat: repeat-y;
    background-position: left bottom;
    background-attachment: fixed;
    background-position-x: left;
    background-size: contain;
    padding: 3%;
    padding-left: 8%;
    padding-top: 5%;
    padding-right: 10%;
    padding-bottom: 5%;
    text-align: left;
    background-color: rgb(134, 18, 18);
    color: white;
    font-size: 120%;
}
.readyToStart h1{
    letter-spacing: .1rem;
    font-size: 190%;
    font-weight: lighter;
    color: rgb(235, 228, 228);
    line-height: 2.5rem;
}
.talkForm{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}


@media only screen and (max-width: 956px) {
    .talkForm{
        margin-top: 9%;
        margin-bottom: 3%;
        justify-content: center;
    }
    .readyToStart{
        padding-top: 8%;
    }
  }