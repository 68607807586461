.container{
    text-align: left;
    padding: 5%;
}

.details{
    position: relative;
    text-align: left;
    background-color: rgb(236, 226, 209);
    padding: 6%;
    list-style-type: circle;
}

.details h5{
    margin-top: 4%;
    font-weight: bolder;
}
.details ul{
    list-style: disc;
    list-style-position: outside;
    margin-left: 5%;
}
.details ul ul{
    list-style-type: circle;
}
.underLine{
    width: 90%;
    height: 1.2px;
    background-color: rgb(178, 183, 185);
    margin-bottom: 3%;
}
.application-top{
    position: absolute;
    top: 3%;
    right: 5%;
}
.application-bottom{
    position: absolute;
    bottom: 3%;
    right: 5%;
}

@media screen and (max-width: 680px) {
//    .application-top,.application-bottom{
//        display: none;
//    }
.application-bottom, .application-top{
    position: relative;
    left: 30%;
    margin: 3%;

  }
}