.service-item {
    align-items: center;
    text-align: left;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: 20%;
    margin-top: 10px;
}

.service-item-header {
    margin-bottom: 30px;
}

.service-item-description {
    margin-bottom: 30px;
}

.service-item-btn {}