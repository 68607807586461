
.siteMap .sites{
   width: auto;
   height: 300px;
   padding: 5%;
   text-align: left;
   position: relative;
}
.siteMap .sites:nth-child(even){
    background-color: rgb(248, 244, 244);
}
.sites ul{
   margin-left: 2%;
}

.sites h1{
    color: rgb(104, 101, 101);
}
 .siteMap .siteMapImageContainer{
    width: auto;
    height: 85vh;
    position: relative;
}
.backgroundColor{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    background-color: rgb(7, 7, 7);
    opacity: .5;

}
.siteMapImageContainer img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
}
.siteMapImageContainer .home{
    position: absolute;
    display: flex;
    color: white;
    flex-direction: row;
    left: 10%;
    top: 6%;
}

.home_h6{
    color: white !important;
    font-weight: bolder;
    font-size: 20px;
}

.siteMapImageContainer .xivTech{
    position: absolute;
    left: 10%;
    top: 25%;
    text-align: left;
    color: white;
}