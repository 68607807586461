.partners-tile {
    background: #242c2f;
    color: white;
}

.partners-tile-header {
    margin-top: 1%;

}

.partners-tile-images {
    height: 300px;
    width: 300px;
    overflow: hidden;
}

.partners-tile-image {
    height: 100px;
    margin: 10%;
}