
.capabilities2{
    display: inline-block;
    width: 28%;
    margin: 4%;
}

.capabilitiesTop{
    box-shadow: -2.5rem 2.5rem  rgba(0,0,0,0.05);
    margin-bottom: 14%;
}
.capabilitiesTop img{
    width: 100%;
    height: 100%;
 }
 .capabilitiesBottom{
     text-align: left;
 }
 .capabilitiesBottom h3{
     display: inline-block;
     padding-bottom: 1%;
     font-weight: bolder;
 }
 .capabilitiesBottom p{
     margin: 4% 0%;
     padding-right: 3%;
     font-family: 'CircularStd';
 }
 .capabilitiesBottom img{
     width: 30px;
     color: white;
     margin-right: 4%;
     display: inline-block;
 }
 .capabilitiesBottom a{
     color: black !important;
 }
 .capabilitiesBottom span{
     font-size: 1.2rem;
     font-weight: bolder;
 }
 .capabilitiesBottom .innerImgContainer:hover img{
    position: relative;
    animation: displace 1s;
 }



 @media only screen and (max-width: 1200px) {
     .capabilitiesTop{
        box-shadow: -1.5rem 1.5rem  rgba(0,0,0,0.05);
     }
 }


 @media only screen and (max-width: 764px) {
   .capabilities2{
      display: block;
      width: 90%;
      margin: auto;
      margin-top: 12%;
    }
    .capabilitiesTop{
        box-shadow: -1rem 1rem  rgba(0,0,0,0.05);
        margin-bottom: 14%;
        margin-left: 2%;
    }

  }


  @keyframes displace{
    0% {left: 15px;opacity: 0;}
    100%{right: 0px;opacity: 1;}
}