.mainStatsDiv{
    background-color: white;
    color: black;
    padding: 4% 15%;
    border: 1px solid rgb(241, 234, 234);
}

.mainStatsDiv h3{
    color: #1598CB;
    font-weight: bolder;
    font-size: 2.5rem;
}
.mainStatsDiv .bottomLine{
    width: 100%;
    height: 1px;
    background-color: #1598CB;;
}

.mainStatsDiv .experienceDiv{
    padding: 3% 5%;
    text-align: left;
}

.experienceDiv p{
    font-size: 2.2rem;
    font-weight: lighter;
}

.experienceDiv span{
    font-weight: bolder;
    font-size: 2.8rem;
}


@media only screen and (max-width: 800px) {
    .mainStatsDiv .experienceDiv{
        padding: 2% 3%;
    }
    .experienceDiv p{
        font-size: 1.8rem;
    }
    .experienceDiv span{
        font-size: 2.3rem;
    }
  }