.request-demo-button  {
     font-size: 1rem;
     height: 100%;
     padding: .7rem 1.1rem;
     display: inline-flex;
     align-items: center;
     justify-content: center;
     text-decoration: none;
     background-color: #fff;
     font-weight: bolder;
     transition: all ease-in-out 250ms;
     position: relative;
     overflow: hidden;
     z-index: 0;
     font-family: "Avenir Next", Arial, sans-serif;
     cursor: pointer;
     color: #2272FB;
     border: 2px solid #2272FB;
     letter-spacing: 2px;
     animation: demoAnimation 1.5s ease-in-out;
}
.request-demo-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 110%;
    background: #2272FB;
    -webkit-transform-origin: 0 0;
    transform-origin: 0;
    -webkit-transform: translateX(-200%) skewX(45deg);
    transform: translateX(-200%) skewX(45deg);
    transition: -webkit-transform ease-in-out 500ms;
    transition: transform ease-in-out 500ms;
    transition: transform ease-in-out 250ms, -webkit-transform ease-in-out 250ms;
    z-index: -1;
}

.request-demo-button:focus:before,
.request-demo-button:hover:before {
    -webkit-transform: translateX(-40%) skewX(45deg);
    transform: translateX(-40%) skewX(45deg);
}
.request-demo-button:focus,
.request-demo-button:hover {
     border-color: #2272FB !important;
     background-color: transparent;
     color: white;
}




.demoModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.demoModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.demoModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.demoModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}
@keyframes demoAnimation {
    from {opacity: 0;}
    to {opacity: 1;}
  }