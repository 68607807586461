.capabilities-list-card {
    margin-top: 25px;
    border: none;
}

.capabilities-list {
    margin-top: 25px;
    margin-bottom: 25px;
}


.capability-card-title {
    color: #0d3042;
    font-size: 18px;
}

.cardImg{
    width: 50%;
    margin-left: 15%;
}
.container1{
    width: 70%;
    text-align: center;
    margin: auto;
}