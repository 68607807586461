.leftRightContainer{
    position: relative;
}
.left{
   display: inline-block;
   position: relative;
   width: 100%;
   height: 1300px;
   background-color: rgb(243, 240, 240);
}
.right{
    display: inline-block;
    width: 100%;
    top: 0%;
    left: 0%;
    height: 1300px;
    position: absolute;
    background-color: rgb(62, 62, 202);
    clip-path: polygon(55% 0, 100% 0, 100% 100%, 45% 100%);
}
.content{
    position: absolute;
    left: 7%;
    top: 9%;
    width: 25%;
    text-align: left;
}
.content h1{
    color: rgb(65, 65, 66);
    font-weight: lighter;
}

.ContactSalesForm{
    position: absolute;
    width: 40%;
    height: 85%;
    left: 35%;
    overflow: hidden;
    top: 9%;
    padding: 2%;
    text-align: left;
    background-color: rgb(255, 255, 255);
    border-top: 3px solid rgb(66, 66, 231);
}

.ContactSalesForm p{
position: relative;
left: -4%;
}
.privacy{
    margin-left: 4%;
    margin-top: 3%;
    color: rgb(65, 65, 66);
}
.sub{
    font-size: 14px;
    color: rgb(104, 96, 96);
    margin-left: 3%;
}

.contactsales-form-submitted{
    position: relative;
    font-family: "Nunito",sans-serif;
    top: 20%;
    margin: auto;
    width: 100%;
    color: rgb(53, 50, 50);
    text-align: center;
    // border: 3px solid rgb(63, 68, 63);
    padding: 10px;
}
.contactsales-form-submitted p{
    position: relative;
    left: 1%;
    margin-top: 2%;
    margin-bottom: 3%;
    font-size: 1.4rem;
    animation-name: example;
    animation-duration: 2s;
}
.contactsales-form-submitted h4{
    position: relative;
    font-size: 1.7rem;
    margin-top: 5%;
    animation-name: example;
    animation-duration: 2.5s;
}


.salesModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.salesModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.salesModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.salesModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}

.sales-overlay{
    position: fixed;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000,  .4);
}

@keyframes example {
    0%   {opacity: 0;}
    100% {opacity: 1;}
  }



@media only screen and (max-width: 956px) {
 
    .right{
        position: relative;
        height: 750px;
        clip-path: none;
    }
    .content{
        position: relative;
        width: 90%;
    }
    .left{
        height: 750px;
        
    }
    .ContactSalesForm{
        width: 94%;
        left: 3%;
        top: 15%;
        height: 80%;
    }
  }
  @media only screen and (max-width: 450px) {
 
    .left{
        height:850px;
    }
    .right{
        height: 850px;
    }
    .ContactSalesForm{
        width: 94%;
        left: 3%;
        top: 21%;
        height: 75%;
    }
  }



  .salesModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.salesModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}
@media only screen and (max-width: 956px) {
       
    .salesModal h4{
         margin-left: 5%;
    }
    .salesModal p{
        margin-left: 8%;
    }

  }

  