.talk-to-expert-btn {
    color: #1F2020;
    background-color: #F8F7F7;
    border-color: #F8F7F7;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.talk-to-expert-btn:hover {
    color: #FFF !important;
}






