.SearchBox{
    position: relative;
    left: -150%;
}
.SearchInput{
    border: none;
    box-sizing: border-box;
    border-bottom: 1px solid black;
    width: 700px;
    padding: 2.5%;
}
.SearchInput:focus{
    border: none;
    box-sizing: border-box;
    outline: 0px;
}

.popularSearches{
    margin-top: 0px;
    display: inline-block;
    position: absolute;
    width: 700px;
    left: 0%;
    z-index: 1;
    font-size: 16px;
    background-color: white;
    color: rgb(128, 121, 121);
    padding: 2%;
    padding-top: -2%;
    text-align: left;
    font-family: system-ui,sans-serif;
    border-top: 2.5px solid palegreen;
}
.popularSearches h6{
    margin: 2%;
    font-size: 14px;
}
.popularSearches p{
    padding: 1%;
    font-weight: lighter;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.popularSearches p:hover{
    background-color: rgb(241, 237, 237);
}
.popularSearches a{ 
    color: rgb(61, 58, 58);
    padding-left: 4%;
    margin: 0%;
}
.popularSearches h6{
    font-weight: lighter;
}
.notMatching{
    border-top: 2.5px solid palegreen;
    margin-top: 5px;
    display: inline-block;
    position: absolute;
    width: 700px;
    left: -150%;
    top: 100%;
    z-index: 99999;
    background-color: white;
    color: black;
    padding: 2%;
    padding-top: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
}
.notMatching a{
    color: black;
    padding-left: 4%;
    font-weight: lighter;
}
.notMatching li{
    padding-top: 1%;
    padding-bottom: 1%;
}
.notMatching li:hover{
    background-color: rgb(241, 237, 237);
}
.notMatching ul h6{
    font-weight: bold;
    color: rgb(128, 121, 121);
}
.searchList .searchLinks{
    padding-top: 5%;
}

.searchLinks a{
    color: rgb(66, 63, 63);
    font-weight: lighter;
    margin: 3%;
    margin-top: 6%;
}
.searchLinks:hover{
    background-color: rgb(241, 237, 237);
}
.sLinks{
    border-top: 2.5px solid palegreen;
    display: inline-block;
    position: absolute;
    width: 700px;
    left: -150%;
    z-index: 1;
    background-color: white;
    color: black;
    padding: 1%;
    text-align: left;
    font-family: system-ui,sans-serif;
}
.sLinks ul li{
    padding-top: 1%;
    padding-bottom: 1%;
}
