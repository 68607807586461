
.applicationForm{
    position: fixed;
    top: 8.5%;
    padding-top: 5%;
    padding-bottom: 16%;
}
.appModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.appModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.appModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.appModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}

.reactLoading{
    position: relative;
    left: 45%;
}

@media only screen and (max-width: 956px) {
       
    .appModal h4{
         margin-left: 5%;
    }
    .appModal p{
        margin-left: 8%;
    }

  }