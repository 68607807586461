
.workWithUs{
    background-color: rgb(240, 238, 235);
    width: 115%;
    margin-top: 4%;

}
.workWithUs .container{
    padding-left: 9%;
}
.departments{
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
}
h2{
    font-weight: 600;
    font-size: 240%;
    margin-bottom: 0%;
}
.h2underline{
    width: 3%;
    background-color: red;
    height: 2px;
}
p{
    font-size: 110%;
}
.departments p{
    margin-right: 20px;
    color: black;
    font-weight: bolder;
    font-size: 110%;
    text-align: left;
    cursor: pointer;
}
.departments P:hover{
    border-bottom: 2px solid red;
}

.l{
    color: rgb(240, 238, 235);
}
.horizontalline{
    width: 95%;
    margin-top: .8%;
    height: 1px;
    background-color: rgb(211, 205, 205);
   
}
.d{
    color: aqua
}
.jobsDiv{
    box-sizing: border-box;
    width: 290px;
    padding: 1%;
    display: inline-block;
    margin: 4px;
    border-left: 4px solid red;
    cursor: pointer;
}
.jobsDiv:hover{
    
    box-shadow: 3px 5px 3px rgb(173, 168, 168);
}
.jobsDiv .card-title{
    font-weight: 900;
    color: black;
    font-size: 100%;
}

.no_jobs{
    text-align: left;
}
.no_jobs p:nth-child(3){
    color: red;
}
.no_jobs p:nth-child(4){
    display: inline-block;
    margin-bottom: 0%;
}

.modal-90w {
     max-width: 80% !important;
}