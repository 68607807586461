
.capabilities_container{
    width: 70%;
    margin: 3% auto;
    display: inline-block;
}
.capabilities_right{
    text-align: left;
}
.capabilities_right h3{
    border-bottom: 2px solid black;
    display: inline-block;
    padding-bottom: 1%;
    font-weight: bolder;
}
.capabilities_right p{
    margin: 4% 0%;
    padding-right: 3%;
    font-family: 'CircularStd';
}
.capabilities_left{
    box-shadow: -2.5rem 2.5rem  rgba(0,0,0,0.05);
}
.capabilities_right img{
    width: 30px;
    color: white;
    margin-right: 4%;
    display: inline-block;
}
.capabilities_right a{
    color: black !important;
}
.capabilities_right span:hover .capabilities_right img{
    width: 60px;
    // font-size: 2rem;
}
.capabilities_right span{
    font-size: 1.2rem;
    font-weight: bolder;
}

.capabilitiesLeft .innerImgContainer:hover img{
    position: relative;
    animation: displace 1s;
}
.capabilities_right .innerImgContainer:hover img{
    position: relative;
    animation: displace 1s;
}

@keyframes displace{
    0% {left: 15px;opacity: 0;}
    100%{right: 0px;opacity: 1;}
}





.webDiv{
    background-color: #f9f9f9;
    padding: 4%;
    margin: 4% 0%;
}
.capabilitiesRight{
    box-shadow: 2.5rem 2.5rem  rgba(0,0,0,0.05);
}
.capabilitiesLeft{
    text-align: left;
}
.capabilitiesLeft h3{
    border-bottom: 2px solid black;
    display: inline-block;
    padding-bottom: 1%;
    font-weight: bolder;
}
.capabilitiesLeft p{
    margin: 4% 0%;
    padding-right: 3%;
    font-family: 'CircularStd';
}
.capabilitiesLeft img{
    width: 30px;
    color: white;
    margin-right: 4%;
    display: inline-block;
}
.capabilitiesLeft a{
    color: black !important;
}
.capabilitiesLeft span{
    font-size: 1.2rem;
    font-weight: bolder;
}



@media only screen and (max-width: 1200px) {
    .capabilities_container{
        width: 75%;
    }
    .capabilities_left{
        box-shadow: -1.4rem 1.4rem  rgba(0,0,0,0.05);
    }
    .capabilitiesRight{
        box-shadow: 1.4rem 1.4rem  rgba(0,0,0,0.05);
    }
  }

@media only screen and (max-width: 764px) {
    .capabilities_container{
        width: 87%;
    }
    .capabilities_left{
        margin: 10% 2%;
        box-shadow: -1.2rem 1.2rem  rgba(0,0,0,0.05);
    }
    .capabilitiesRight{
        box-shadow: 1rem 1.2rem  rgba(0,0,0,0.05);
    }
    .capabilities_right h3{
        font-size: 1.3rem;
    }
  }