.how-we-work-item-header {
    text-align: left;
    font-weight: bold;
}

.how-we-work-item-description {
    text-align: left;
}

.how-we-work-item {
    padding-left: .5rem;
    border-width: 2px;
    border-style: solid;
    border-image:
    linear-gradient(to bottom, rgb(232, 9, 176) 0%, #6709e2 100%) 1 100%;
    border-left: .35rem solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    height: 100%;
}

.how-we-work-card{
    height: 100%;
    transition: all .4s;
}

@media only screen and (max-width: 956px) {
    .how-we-work-item{
        margin-bottom: 25px;
    }
  }
