.headerParentDiv{
    position: relative;
    font-family: Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    padding: 100px 110px;
    padding-left: 20%;
    padding-right: 20%;
    background: #fff;
    color: #000;
    text-align: left;
    animation: headerAnimation 1.5s ease-out;
}

.headerParentDiv .spanContainer{
    padding: 24px 0 20px;
}
.headerParentDiv .spanContainer span{
    font-size: 19px;
    font-weight: 700;
}
.headerParentDiv .h1Container{
    padding: 0 0px 55px;
    // padding-right: 8%;
}
.headerParentDiv .h1Container h1{
    font-family: Quarto Light,source-han-serif-sc,serif;
    font-style: normal;
    font-weight: 100;
    font-size: 5.5rem;
    line-height: 100px;
}
.headerParentDiv .pContainer{
    font-size: 110%;
    font-family:sans-serif;
    padding-right: 10%;
}
.headerParentDiv .pContainer p{
    font-weight: 400;
    font-family: GT America,source-han-sans-simplified-c,sans-serif;
    font-size: 24px;
    line-height: 32px;
}

@media screen and (max-width: 1600px) {
   .headerParentDiv{
    padding-left: 8%;
    padding-right: 3%;
   }
   .headerParentDiv .h1Container h1{
       font-size: 4.9rem;
       line-height: 82px;
   }
  }

@media screen and (max-width: 756px) {
    .headerParentDiv{
        padding-top: 2%;
        padding-left: 4%;
        padding-right: 3%;
    }
    .headerParentDiv .h1Container h1{
        font-size: 3rem;
        line-height: 52px;
    }
    .headerParentDiv .pContainer p{
        font-size: 16px;
        line-height: 25px;
    }
  }

  @keyframes headerAnimation {
    from {opacity: 0;top: 30px;}
    to {opacity: 1;top: 0px;}
  }