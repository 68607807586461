.breadcrumb{
  background-color: white;
  font-size: 15px;
  margin-top: 1.5%;
  font-family: "Open Sans",sans-serif;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.3rem;
    padding-left: 0.3em;
    font-weight: bolder;
    color: #000000;
    content: ">";
  }
  .breadcrumb-item a{
    color: #147cb5;
  }