.mainDesignDiv{
    position: relative;
    background-color: white;
    padding: 30% 0%;
    padding-top: 10%;
    margin-bottom: 8%;
}
.topDesign{
   position: absolute;
   height: 20%;
   width: 65%;
   top: 1%;
   left: 17%;
   z-index: 2;
}
.topDesign img{
  object-fit: cover;
}
.midDesign{
    position: relative;
    width: 100%;
    height: 165rem;
    color: white; 
    object-fit: cover;
    background: #202226 !important;
    text-align: left;
}
.midDesign .innerMidDiv{
    position: absolute;
    top: 22%;
    left: 17%;
    width: 70%;
}
.innerMidDiv .card{
    height: 30%;
    background-color: black;
}

.innerMidDiv .cardsDiv{
    margin-top: 8%;
}
.innerMidDiv .heading2{
  color: #959595 !important;
}
.innerMidDiv h3{
  font-family: freight-display-pro,serif;
  padding-right: 30%;
  line-height: 35px;
  font-size: 1.5rem;
}
.liveText{
  margin-bottom: 12%;
}
.liveText h5{
  margin: 5% 0%;
  font-size: 16px;
  color: #959595 !important;
}

.leftAnimation .leftP1{
  margin: 0%;
  margin-left: 1%;
  font-size: 600%;
  font-weight: bolder;
}
.leftAnimation .leftP2{
  margin: 0%;
  margin-left: 10%;
  font-size: 550%;
  font-weight: bolder;
}
.leftAnimation .leftP3{
  margin: 0%;
  margin-left: 30%;
  font-size: 590%;
  font-weight: bolder;
  font-weight: lighter;
  font-style: oblique;
}
.cardSpecific{
  background-color: #202226 !important;
}
.cardText{
  // padding-right: 10%;
  line-height: 180%;
}
.cardText p{
  margin: 1%;
  font-size: 18px;
}

.rightAnimationText{
  padding-top: 63%;
  line-height: 38px;
  text-align: left;
  padding-left: 8%;
}
.bottomDesign{
   position: absolute;
   z-index: 2;
   height: 21%;
   width: 65%;
   bottom: -1%;
   left: 16%;
}
.bottomDesign img{
  object-fit: cover;
}







@media only screen and (max-width: 956px) {
    .topDesign{
      width: 95%;
      height: 20%;
      left: 2.5%;
      top: -.1%;
    }
    .bottomDesign{
        width: 100%;
        left: 0%;
        height: 20%;
    }
    .midDesign .innerMidDiv{
        width: 90%;
        left : 4%
    }

  }
  @media only screen and (max-width: 768px) {
    .rightAnimationText{
      padding-top: 12%;
      padding-right: 8%;
    }
    .mainDesignDiv .innerMidDiv{
      top: 20%;
    }
    .innerMidDiv h3{
      padding-right: 5%;
      font-size: 1.7rem;
      line-height: 35px;
    }
    .cardText{
      padding-right: 0%;
    }
    .midDesign{
      height: 180rem;
    }
  }

  @media only screen and (max-width: 575px) {

    .mainDesignDiv{
        margin-bottom: 15%;
    }
      .topDesign{
          height: 9%;
      }
    .midDesign{
        height: 240rem;
    }
    .midDesign .innerMidDiv{
        top: 12%;
        left: 9%;
    }
    .bottomDesign{
        width: 90%;
        left: 5%;
        height: 10%;
    }
    .leftAnimation .leftP1{
      margin: 0%;
      font-size: 440%;
    }
    .leftAnimation .leftP2{
      margin-left: 10%;
      font-size: 420%;
    }
    .leftAnimation .leftP3{
      margin-left: 20%;
      font-size: 440%;
    }
  }







  