.cookieModal1{
    // height: 95vh;
    // position: fixed;
    top: 8.4%;
    left: -1%;
    padding: 4%;
    padding-bottom: 15%;
    width: 126%;

}
.cookieModal2{
    top: 9%;
    // left: 4%;
    padding-bottom: 4%;
    // width: 120%;

}
.cookieInnerDiv{
    // height: 100%;
    // overflow: auto;
    // height: 800px;
    // margin-left: 0%;
    padding: 0% 5%;
}
.cookieBannerDiv span{
    color: black;
    border-bottom: 1px solid #2272FB;
    cursor: pointer;
}
.cookieh6{
    border: 2px solid black;
    border-right: 0px;
    padding: 10px;
    margin-top: 8px;
}
.cookieBannerDiv{
    display: inline-block;
}