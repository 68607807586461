.fluid{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.reachOut{
    padding: 2%;
}

.display-6{
    font-size: 100%;
    margin-bottom: 1%;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.4;
    font-size: 2.4rem;
}

@media only screen and (max-width: 400px) {
   .display-6{
       font-size: 2rem;
   }
  }