.view-service-tile-description {
    padding: 1rem;
    border-width: 2px;
    border-style: solid;
    border-image:
        linear-gradient(to bottom, rgb(231, 9, 176) 0%, #6709e2 100%) 1 100%;
    border-left: .35rem solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    color: white;
}

.view-service-tile-header {
    margin-top: 3%;
    display: flex;
    align-content: center;
    padding: 1rem;
    border-width: 2px;
    border-style: solid;
    border-image:
        linear-gradient(to bottom, rgb(232, 9, 176) 0%, #6709e2 100%) 1 100%;
    border-left: .35rem solid;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    color: white;
}

.view-services-tile {
    background: linear-gradient(to right, #131212 0, rgb(68, 68, 119) 50%, rgb(94, 53, 136) 100%);
}

.view-service-tile-btn {
    color: #fff;
    background-color: #2E2EFE;
    border-color: #2E2EFE;
}

.view-service-tile-btn:hover {
    background-color: rgb(80, 80, 212);
    border-color: rgb(52, 52, 221);
}
