
.footer-services-items {
    margin-top:4%;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
}

.footer-services-header{
    font-weight: bolder;
    font-size: larger;
}