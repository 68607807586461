@mixin footer {
    background-color: #000;
    width: 100%;
    color: white;
    padding-top: 5%;
}

%footer {
    @include footer;
}

.footer {
    @extend %footer;
}

@media only screen and (max-device-width: 480px) {
    .footer {
        @include footer;
        padding-left: 7%;
        padding-right: 7%;
        text-align: center;
    }
}

.footer>.container {
    //padding: 1rem 1rem 2rem 2rem;
}

.footer-company-logo {
    display: flex;
    text-align: center;
    font-weight: bold;
    margin-bottom: 3%;
    //padding-bottom: 3%;
}
.footer-company-logo img{
    width: 110px;
}
.footer-company-logo .img1{
    width: 65px;
    height: 65px;
}

.footer-company-description {
    text-align: left;
}


.footer-list {
 display: flex;
 flex-direction: row;
 justify-content: center;
}

.footer-company-items {
    margin-top: 4%;
}

.footer-company-items a{
color: white;

}
.footer-company-items p{
  margin-bottom: 3.5%;
    
    }

.footer-company-header {
    font-weight: bolder;
    font-size: larger;
}
.site_map{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media screen and (max-width: 700px) {
    .footer-company-logo {
        justify-content: center;
    }
   
  }
