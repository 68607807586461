.service-jumbotron {
    padding: 0 !important;
    max-height:500px !important;
    // max-width: 100px !important;
}
.service-jumbotron-dark{
    background: #eaeceef8;
}

.service-jumbotron-light{
    background: white;
}

.service-item-title-link {
    font-size: 23px;
    // color: white;
}

.service-item-title {
    position: absolute;
    bottom: 0;
    left: 50px;
}

// .service-jumbotron {
//     height: 500px !important;
//     }

// .service-item.service-item-bg-image {
//     background-size: cover;
//     background-position: center center;
//     position: relative;
// }
// .service-item.service-item-bg-image .service-item-bg-static {
//     background-size: cover;
//     background-position: center center;
//     opacity: 1;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }
// .service-item.service-item-bg-image:hover,
// .service-item.service-item-bg-image:active,
// .service-item.service-item-bg-image:focus {
//     outline: none;
// }
// .service-item.service-item-bg-image:hover .service-item-bg-static,
// .service-item.service-item-bg-image:active .service-item-bg-static,
// .service-item.service-item-bg-image:focus .service-item-bg-static {
//     opacity: 0;
// }
// .service-item.service-item-bg-image:hover .service-item-content:before,
// .service-item.service-item-bg-image:active .service-item-content:before,
// .service-item.service-item-bg-image:focus .service-item-content:before {
//     opacity: 0.5;
// }
// .service-item.service-item-bg-image .service-item-content {
//     position: relative;
//     z-index: 2;
// }
// .service-item.service-item-bg-image .service-item-content:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 1;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
//     z-index: -1;
//     display: block;
//     background: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(#000));
//     background: linear-gradient(to bottom, transparent 33%, #000 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
// }

// .service-item.service-item-feature .service-item-content .service-item-link {
//     font-size: 1rem;
// }

// .service-item.service-item-link {
//     text-decoration: none;
//     color: #fff;
// }
// .service-item.service-item-link .service-item-title span {
//     background-size: 0px 5px;
//     background-position: 0px 105%;
//     -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     padding: 0.1% 0px;
//     background-repeat: no-repeat;
//     background-image: -webkit-gradient(linear, left top, right top, from(#00c9ff), to(#00c9ff));
//     background-image: linear-gradient(to right, #00c9ff 0%, #00c9ff 100%);
//     overflow: visible;
// }
// .service-item.service-item-link:hover,
// .service-item.service-item-link:active,
// .service-item.service-item-link:focus {
//     outline: none;
//     text-decoration: none;
// }
// .service-item.service-item-link:hover .service-item-title span,
// .service-item.service-item-link:active .service-item-title span,
// .service-item.service-item-link:focus .service-item-title span {
//     text-decoration: none;
//     background-size: 100% 5px;
// }
// .service-item.service-item-link .service-item-content {
//     height: 100%;
//     width: 100%;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: end;
//     -ms-flex-align: end;
//     align-items: flex-end;
//     padding: 1.5rem 2rem;
// }
// .service-item.service-item-link .service-item-content > * {
//     margin-bottom: 0;
// }
// .service-item.service-item-link .service-item-content .service-item-title {
//     font-size: 2rem;
//     line-height: 2.3125rem;
//     width: 28.375rem;
//     max-width: 100%;
// }
// .grid-item-half .service-item.service-item-link .service-item-content .service-item-title {
//     font-size: 1.5rem;
//     line-height: 1.625rem;
// }
// @media (max-width: 991.98px) {
//     .service-item.service-item-link .service-item-content .service-item-title {
//         width: 100%;
//     }
// }

// .service-item {
//     width: 100%;
//     height: 100%;
// }


// .service-item {
//     width: 100%;
//     height: 100%;
// }
// .service-item.service-item-image-full {
//     width: 100%;
//     height: 100%;
//     background-size: cover;
//     background-position: center center;
// }
// .service-item.service-item-feature {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     align-items: center;
//     padding: 2rem 1.5rem;
// }
// .service-item.service-item-feature .service-item-content {
//     width: 40.625rem;
//     max-width: 100%;
// }
// .service-item.service-item-feature .service-item-content .service-item-title {
//     font-size: 2.5rem;
//     margin-bottom: 0;
// }
// .service-item.service-item-feature .service-item-content .service-item-subtitle {
//     font-size: 1.25rem;
//     margin: 2rem 0;
//     width: 28.125rem;
//     max-width: 100%;
// }
// .service-item.service-item-feature .service-item-content .service-item-link {
//     font-size: 1rem;
// }
// @media (max-width: 991.98px) {
//     .service-item.service-item-feature .service-item-content {
//         width: 100%;
//     }
//     .service-item.service-item-feature .service-item-content .service-item-title,
//     .service-item.service-item-feature .service-item-content .service-item-subtitle {
//         width: 100%;
//     }
// }
// .service-item.service-item-feature:not(.service-item-theme-light) .btn:hover,
// .service-item.service-item-feature:not(.service-item-theme-light) .btn:active,
// .service-item.service-item-feature:not(.service-item-theme-light) .btn:focus {
//     outline: none;
//     color: #fff !important;
// }
// .service-item.service-item-feature.service-item-theme-image {
//     color: #fff;
//     background-size: cover;
//     background-position: center center;
// }
// .service-item.service-item-feature.service-item-theme-image:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 0;
//     background-image: radial-gradient(50% 200% ellipse at 100% 50%, transparent 35%, #000 100%);
// }
// .service-item.service-item-feature.service-item-theme-image .service-item-content {
//     z-index: 1;
// }
// .service-item.service-item-feature.service-item-theme-black {
//     background-color: #000;
//     color: #fff;
// }
// .service-item.service-item-feature.service-item-theme-gray {
//     background-color: #333333;
//     color: #fff;
// }
// .service-item.service-item-feature.service-item-theme-light {
//     background-color: #f8f7f7;
//     color: #1f2020;
// }
// @media (max-width: 991.98px) {
//     .service-item.service-item-feature {
//         width: 100%;
//     }
// }
// .service-item.service-item-feature.service-item-hero h1 {
//     margin-bottom: 1.25rem;
// }
// .service-item.service-item-feature.service-item-hero p {
//     font-size: 1.25rem;
// }
// .service-item.service-item-feature.service-item-hero .service-item-content > * {
//     max-width: 80%;
// }
// .service-item.service-item-feature.service-item-hero-aux h2 {
//     font-size: 0.875rem;
//     margin-bottom: 1.5625rem;
//     text-transform: uppercase;
//     font-weight: bold;
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links {
//     list-style: none;
//     margin: 0;
//     padding: 0;
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item {
//     padding: 1.5625rem 0;
//     border-bottom: 1px solid rgba(255, 255, 255, 0.21);
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item:first-child {
//     border-top: 1px solid rgba(255, 255, 255, 0.21);
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link {
//     padding: 0;
//     color: #fff;
//     font-size: 1.125rem;
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link > span {
//     background-size: 0px 3px;
//     background-position: 0px 105%;
//     -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     padding: 0.1% 0px;
//     background-repeat: no-repeat;
//     background-image: -webkit-gradient(linear, left top, right top, from(#00c9ff), to(#00c9ff));
//     background-image: linear-gradient(to right, #00c9ff 0%, #00c9ff 100%);
//     overflow: visible;
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:hover,
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:active,
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:focus {
//     outline: none;
//     text-decoration: none;
// }
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:hover > span,
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:active > span,
// .service-item.service-item-feature.service-item-hero-aux .hero-links .nav-item .nav-link:focus > span {
//     text-decoration: none;
//     background-size: 100% 3px;
// }
// .service-item.service-item-feature.service-item-hero-aux .underlined-link {
//     margin-top: 1.5625rem;
//     display: inline-block;
// }
// .service-item.service-item-link {
//     text-decoration: none;
//     color: #fff;
// }
// .service-item.service-item-link .service-item-title span {
//     background-size: 0px 5px;
//     background-position: 0px 105%;
//     -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
//     padding: 0.1% 0px;
//     background-repeat: no-repeat;
//     background-image: -webkit-gradient(linear, left top, right top, from(#00c9ff), to(#00c9ff));
//     background-image: linear-gradient(to right, #00c9ff 0%, #00c9ff 100%);
//     overflow: visible;
// }
// .service-item.service-item-link:hover,
// .service-item.service-item-link:active,
// .service-item.service-item-link:focus {
//     outline: none;
//     text-decoration: none;
// }
// .service-item.service-item-link:hover .service-item-title span,
// .service-item.service-item-link:active .service-item-title span,
// .service-item.service-item-link:focus .service-item-title span {
//     text-decoration: none;
//     background-size: 100% 5px;
// }
// .service-item.service-item-link .service-item-content {
//     height: 100%;
//     width: 100%;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -webkit-box-align: end;
//     -ms-flex-align: end;
//     align-items: flex-end;
//     padding: 1.5rem 2rem;
// }
// .service-item.service-item-link .service-item-content > * {
//     margin-bottom: 0;
// }
// .service-item.service-item-link .service-item-content .service-item-title {
//     font-size: 2rem;
//     line-height: 2.3125rem;
//     width: 28.375rem;
//     max-width: 100%;
// }
// .grid-item-half .service-item.service-item-link .service-item-content .service-item-title {
//     font-size: 1.5rem;
//     line-height: 1.625rem;
// }
// @media (max-width: 991.98px) {
//     .service-item.service-item-link .service-item-content .service-item-title {
//         width: 100%;
//     }
// }
// .service-item.service-item-bg-image {
//     background-size: cover;
//     background-position: center center;
//     position: relative;
// }
// .service-item.service-item-bg-image .service-item-bg-static {
//     background-size: cover;
//     background-position: center center;
//     opacity: 1;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }
// .service-item.service-item-bg-image:hover,
// .service-item.service-item-bg-image:active,
// .service-item.service-item-bg-image:focus {
//     outline: none;
// }
// .service-item.service-item-bg-image:hover .service-item-bg-static,
// .service-item.service-item-bg-image:active .service-item-bg-static,
// .service-item.service-item-bg-image:focus .service-item-bg-static {
//     opacity: 0;
// }
// .service-item.service-item-bg-image:hover .service-item-content:before,
// .service-item.service-item-bg-image:active .service-item-content:before,
// .service-item.service-item-bg-image:focus .service-item-content:before {
//     opacity: 0.5;
// }
// .service-item.service-item-bg-image .service-item-content {
//     position: relative;
//     z-index: 2;
// }
// .service-item.service-item-bg-image .service-item-content:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     opacity: 1;
//     -webkit-transition: all 0.3s;
//     transition: all 0.3s;
//     z-index: -1;
//     display: block;
//     background: -webkit-gradient(linear, left top, left bottom, color-stop(33%, transparent), to(#000));
//     background: linear-gradient(to bottom, transparent 33%, #000 100%);
//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 );
// }
// .service-item.service-item-video {
//     position: relative;
// }
// .service-item.service-item-video .video-button {
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     z-index: 3;
//     -webkit-transform: translate(-50%, -50%);
//     transform: translate(-50%, -50%);
// }
// .service-item.service-item-video:hover,
// .service-item.service-item-video:active,
// .service-item.service-item-video:focus {
//     outline: none;
// }
// .service-item.service-item-video:hover .video-button,
// .service-item.service-item-video:active .video-button,
// .service-item.service-item-video:focus .video-button {
//     background-color: #00c9ff;
// }
// @media (max-width: 991.98px) {
//     .service-item.service-item-video .service-item-content > * {
//         display: none;
//     }
// }