.servicesMainContainer{
    background-color: white;
    color: black;
}
.servicesMainContainer .firstContainer{
    width: 65%;
    margin:auto;
    text-align: left;
    padding: 4% 0%;
}
.servicesMainContainer .secondContainer{
    width: 75%;
    margin:auto;
    text-align: left;
    padding: 6% 0%;
}

.servicesMainContainer p{
    font-weight: bolder;
    margin: 2% 0%;
    font-size: 20px;
}
.servicesMainContainer .serviceImage{
    width: 100%;
}
.servicesMainContainer .serviceImage img{
    object-fit: cover;
}
.servicesMainContainer h1{
    margin: 5% 0%;
    font-size: 5vw;
    font-weight: lighter;
    line-height: 6vw;
    font-family: cursive;
}
.servicesMainContainer h4{
    font-size: 1.5vw;
}
.servicesMainContainer h3{
    margin: 3% 0%;
    font-size: 3.5vw;
    font-family: cursive;
}
.servicesMainContainer h5{
    margin: 8% 0%;
    font-size: 1.4vw;
    // font-family: cursive;
}

.line{
    width: 100%;
    height: 3px;
    background-color: black;
    margin-bottom: 3%;
}

@media only screen and (max-width: 800px) {
     .servicesMainContainer .firstContainer{
         width: 95%;
         padding: 13% 3%;
     }
     .servicesMainContainer h1{
         font-size: 7.5vw;
         line-height: 7.5vw;
     }
     .servicesMainContainer h3{
         font-size: 9vw;
     }
     .servicesMainContainer h4{
         font-size: 2.7vw;
     }
     .servicesMainContainer h5{
         font-size: 3vw;
         margin: 7% 0%;
     }
     .servicesMainContainer .serviceImage{
         height: 40vw;
     }
     .servicesMainContainer .container p{
         font-size: 16px;
     }

  }

  @media only screen and (max-width: 1500px) {
    .servicesMainContainer .secondContainer{
        width: 90%;
        padding: 15% 0%;
    }
  }