// .home-teaserr {
//     position: relative;
//     background-color: white !important;
//     padding-right: 2%;
// }
// .column{
//     position: relative;
// }
// .home-teaser-con {
//     // display: inline-block;
//     position: absolute;
//     text-align: left;
//     z-index: 6;
//     width: 130%;
//     height: 32%;
//     overflow-wrap: break-word;
//     top: 30%;
//     left: 18%;
//     align-items: center;
//     background: linear-gradient(to right, #131212 0, rgb(68, 68, 119) 50%, rgb(94, 53, 136) 100%);
//     align-content: center;
//     padding-top: 10%;
//     border-width: 2px;
//     border-style: solid;
//     border-image:
//     linear-gradient(to bottom, rgb(231, 9, 176) 0%, #6709e2 100%) 1 100%;
//     border-left: .35rem solid;
//     border-right: 0;
//     border-top: 0;
//     border-bottom: 0;
//     color:white;
// }
// .home-teaser-con-title{
//     font-size: 250%;
//     position: static;
//     left: 5%;
//     letter-spacing: 0%;
//     line-height: 2.5rem;
//     top: 35%;
// }
// @media only screen and (max-width: 1350px) {
//     .home-teaser-con{
//         padding-top: 5%;
//     }
//     .home-teaser-con-title{
//         font-size: 200%;
//     }

//   }
//   @media only screen and (max-width: 800px) {
//     .home-teaser-con{
//         padding-top: 4%;
//     }
//     .home-teaser-con-title{
//         font-size: 140%;
//     }

//   }
//   @media only screen and (max-width: 660px) {
//     .home-teaser-con{
//         padding-top: 4%;
//     }
//     .home-teaser-con-title{
//         font-size: 100%;
//     }

//   }
.leftcard{
    position: relative;
    left: 15%;
    top: 32%;
    background-color: blueviolet;
    text-align: left;
    background: linear-gradient(to right, #131212 0, rgb(68, 68, 119) 50%, rgb(94, 53, 136) 100%);
    background-image:  linear-gradient(to bottom, rgb(231, 9, 176) 0%, #6709e2 100%) 1 100%;
    border-left: .35rem solid;
    border-left: .35rem solid;
    border-width: 2px;
    border-style: solid;
    height: 40%;
    width: 130%;
    z-index: 10;
    padding-top: 8%;
    color:white;
    overflow: hidden;
}
.leftcard p{
    margin: 0;
    position: absolute;
    top:48%;
    margin-left: 0%;
    font-size: 250%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    letter-spacing: 1px;
    word-spacing: 1px;
    line-height: 1;
    margin-left: 5px;
}
.rightimg{
    position: relative;
    right: 10%;
}

  @media only screen and (max-width: 1050px) {

    .leftcard p{
        font-size: 210%;
    }

  }
  @media only screen and (max-width: 700px) {

    .leftcard p{
        font-size: 150%;
    }

  }
  @media only screen and (max-width: 580px) {

    .leftcard p{
        font-size: 130%;
    }

  }
  @media only screen and (max-width: 500px) {

    .leftcard p{
        font-size: 100%;
    }

  }
  @media only screen and (max-width: 400px) {

    .leftcard p{
        font-size: 80%;
    }

  }
  @media only screen and (max-width: 350px) {

    .leftcard p{
        font-size: 60%;
    }

  }