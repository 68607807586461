.arrowLink{
    width: 25px;
    margin-left: 20px;
}

.serviceSerachItem{
    position: relative;
    margin: 0%;
    padding: 3% 0% !important;
    border-top: 2px solid black;
}
.serviceSerachItem a{
    font-weight: bolder;
    color: black;
    font-size: 1.2rem;
}
.serviceSerachItem span{
    position: absolute;
    left: 80%;
    top: 5%;
    font-weight: bolder;
    font-size: 30px;
    cursor: pointer;
}

.serviceSerachItem a:hover{
    color: rgb(92, 89, 89);
}
.serviceSerachItem a:hover .arrowLink{
    position: relative;
    animation: displace 1s;
}

@media only screen and (max-width: 1000px) {
    .serviceSerachItem a{
        font-size: .8rem;
    }
}

@keyframes displace{
    0% {left: 10px;opacity: 0;}
    100%{right: 0px;opacity: 1;}
  }