
html,body{
  overflow-x: hidden;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a:any-link{
  text-decoration: none;
}

.nav {
  position: relative;
  background-color: white;
  min-height: 80px;
  padding-top: .5%;
  align-content: center;
  font-family: "Segoe UI","Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
.navShadow{
  position: fixed;
  top: 0%;
  width: 100%;
  box-shadow: 0px 0px 4px grey;
  z-index: 9999;
}
.navShadow2{
  position: relative;
  margin-top: -10%;
  z-index: 9999;
}


.nav2{
  background-color: black;
  // transition: all .1s;
  animation:  nav2 .3s ease-in-out;
}

.nav-item2{
  color: white;
}
.nav-logo {
  color: black;
  font-weight: bold;
  margin-top: 1.5rem;
}

.nav-item {
  color: black;
  font-size: 18px;
  cursor: pointer;
}

.nav-item-dropdown-caret{
  color: black;
  margin-left: 2px;
}
.nav-sub-panel {
  padding-left: 10%;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 100;
  box-shadow: 0px 0px 5px grey;
  padding-bottom: 1.2%;
  animation: subpannel .3s ease-in-out;
}

.nav-sub-panel-item-image {
  max-width: 150px;
  max-height: 150px;
}

.nav-sub-panel-item-media-list {
  margin: 1.1%;
}

.nav-sub-panel-item-media-list>a {
  color: black ;
}

.nav-sub-panel-item-media-list>a:hover {
  color: rgb(31, 29, 29);
  padding-bottom: .25rem;
  text-decoration: none;
}

.searchdemo .nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.common-navbar-searchbar{
  margin-right: 6%;
}
.rdemo{
  position: fixed;
  display: inline-block;
  right: 3%;
  top: 2%;
  z-index: 9999;

}
.mainitems{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}



.faicon{
display: none;
position: absolute;
right: 3.5%;
top: 3.5%;
cursor: pointer;
z-index: 91;
}

.fadots{
display: none;
position: sticky;
left: 1.7%;
top: 2.4%;
cursor: pointer;
}
.barsdiv{
  position: relative;
}

@keyframes subpannel {
  from { opacity: 0;}
  to {opacity: 1;}
}
@keyframes nav2 {
  from { opacity: 0;}
  to {opacity: 1;}
}




@keyframes services{
  0% {
     color: transparent;
  }
  100% {

    color: black;
  }
}
@keyframes bar {
  0% {
    top: -100%;
    color: transparent;
    opacity: 0;
  }
  25%{
    opacity: .25;
  }
  50%{
    opacity: .5;
  }
  75%{
    opacity: .75;
  }
  100% {
    top: 0%;
    opacity: 1;
    color: black;
  }
}

.mobileViewItems{
  display: none;
  position: relative;
}
.logotext{
  display: inline-block;
  position: absolute;
  top: 3%;
  left: 5.5%;
  color: black;
  font-weight: bolder;
  font-family: "Times New Roman", Times, serif;
  font-size: 75%;
}
.mobileViewItems a{
  position: relative;
  display: block;
  color: black;
  font-family: sans-serif;
  margin: 3.5%;
  margin-left: 0%;
  font-size: 120%;
}
.mobileViewItems h3{
  font-family: "Nunito", sans-serif;
  font-weight: bolder;
  font-size: 150%;
  margin-top: 6%;
  margin-bottom: 5%;
}
.mobileViewItems .underlineh2{
  width: 95%;
  height: 4px;
  background-color: #1e9bd7;
  margin-bottom: 5%;
  position: relative;
  
}
.links{
  position: relative;
  left: 5%;
  font-family: sans-serif;
  font-size: 120%;
}
.toggle-link{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0%;
}
.toggle-link .icon{
   position: relative;
   left: 62%;
   font-weight: lighter;
   font-size: 140%;
   color: #1e9bd7;
}
.accordion{
   background-color: white;
   border: 0px;
}
.accordCard{
  background-color: white;
   border: 0px;
}
.cardHeader{
  background-color: white;
  border: none;
  height: 4.1rem;
}
.tech-services{
  -webkit-animation: services .7s ease-in-out;
  // animation-duration: s;
  margin-top: 10%;
}
.tech-services h3{
  font-size: 116%;
  font-weight: bolder;
  margin-top: 8%;
  margin-bottom: 4%;
 font-family: Arial, Helvetica, sans-serif;
}
.tech-services > a{
  font-size: 110%;
  font-family: Arial, Helvetica, sans-serif;  
}
 @media screen and (max-width: 956px) {
  .servicesitem{
    display: none;
  }

  .searchdemo{
    display: none;
  }
  .rdemo{
    display: none;
  }
  .mainitems{
    display: none;
  }


  .nav-items{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .faicon{
    display: flex;
    color: white;
  }

  .fadots{
    display: flex;
  }

  .showingsearchdemo{
    position: absolute;
    top: 60px;
    display: none;
    z-index: 5;
    width: 100px;
    right: -6.5%;
    justify-content: space-between;
    flex-direction: column;
  }

  .mainItemsMobileView{
    position:  absolute;
    top: 0px;
    left: 0%;
    z-index: 10;
    display: none;
    animation-duration: 4s;
    -webkit-animation: bar .5s ease;
    width: 100%;
    height: 500px;
    background-color: blue;
  }

  .mobileViewItems{
    position: absolute;
    display: block;
    top: 0%;
    left: 0%;
    width: 100%;
    text-align: left;
    padding-top: 80px;
    z-index: 90;
    background: white;
    animation-duration: 4s;
    box-shadow: 2px 2px 7px rgb(44, 40, 40);
    -webkit-animation: bar .6s ease-in-out;
  }
 
 }

 @media screen and (max-width: 8509px){
   .navShadow2{
     margin-top: -12%;
   }
 }

 @media screen and (max-width: 756px){
   .navShadow2{
     margin-top: -15%;
   }
 }
 @media screen and (max-width: 550px){
  .navShadow2{
    margin-top: -16%;
  }
}
@media screen and (max-width: 500px){
  .navShadow2{
    margin-top: -20%;
  }
}

