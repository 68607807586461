.solvingTogether{
    background-color: beige;
    padding: 6%;
    padding-left: 8%;
    text-align: left;
    position: relative;
}
.solvingTogether h2{
   margin-bottom: 2%;
}
.solvingTogether sup{
    font-weight: lighter;
    font-size: 25px;
}

.imgContainer{
    position: relative;
}

.imgContainer .imgInnerContainer img{
  width: 100%;
  height: 100%;
}

.imgContainer .imgInnerContainer{
         width: 73%;
         height: 350px;            
         position: absolute;
         top: 4%;
         transform: skewX(-8deg);
         z-index: 9;
         left: -9%;
         margin-left: 20%;
    }

 .imgContainer .background2{
    position: relative;
    color: red;
    background-color: red;
    width: 42%;
    transform: skewX(-8deg);
    height: 410px;
    opacity: .8;
    margin-left: 20%;
    margin-top: -1%;
 }
.imgContainer .background1{
    color: rgb(121, 134, 90);
    background-color: rgb(46, 16, 25);
    width: 42%;
    position: absolute;
    background-color: #005de0;
    height: 410px;
    margin-left: 20%;
    transform: skewX(-8deg);
    left: 12%;
    top: -4.5%;
}



@media only screen and (max-width: 956px) {
     .imgContainer{
         margin-top: 10%;
     }
  }

 