%background-gray-color {
    background-color: #f2f2f2 !important;
}


.home-bg-color-gray {
    @extend %background-gray-color;
}


.home-section-margin {
    margin-top: 30px;
}

.home-teaser-tile{
    // margin-top: 1%;
    // margin-bottom: 1%;
}