.talkModal{
    position: fixed;
    top: 8.4%;
    padding-top: 5%;
    padding-bottom: 16%;
}


.letUsTalk {
    font-size: 1.5rem;
    font-weight: bolder;
    height: 3.625rem;
    display: inline-flex;
    align-items: center;
    letter-spacing: .1rem;
    justify-content: center;
    border: 2px solid white;
    text-decoration: none;
    background-color: transparent;
    padding: 0 2.3rem;
    border-radius: 2px;
    transition: all ease-in-out 250ms;
    position: relative;
    overflow: hidden;
    z-index: 0;
    // margin-right: 7%;
    font-family: "Avenir Next", Arial, sans-serif;
    cursor: pointer;
    color: white;
    // margin-bottom: 2%;
}

.letUsTalk:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 100%;
    background: white;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-200%) skewX(45deg);
    transform: translateX(-200%) skewX(45deg);
    transition: -webkit-transform ease-in-out 250ms;
    transition: transform ease-in-out 250ms;
    transition: transform ease-in-out 250ms, -webkit-transform ease-in-out 250ms;
    z-index: -1;
}
.letUsTalk:focus:before,
.letUsTalk:hover:before {
    -webkit-transform: translateX(-40%) skewX(45deg);
    transform: translateX(-40%) skewX(45deg);
}
.letUsTalk:focus,
.letUsTalk:hover {
    border-color: #111822;
    background-color: transparent;
    color: black;
}



.talkModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.talkModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.talkModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.talkModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}
@media only screen and (max-width: 956px) {
       
    .talkModal h4{
         margin-left: 5%;
    }
    .talkModal p{
        margin-left: 8%;
    }

  }