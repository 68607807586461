.logo{
    color: black;
    font-weight: bold;
}
.logo2{
    color: white;
    font-weight: bold;
}
a .logo1{
    width: 65px;
    height: 65px;
}
.secondImg{
    width: 120px;
}