.contactForm{
    //  width: 700px;
//     height: 300px;
//     background-color:whitesmoke;
    position: fixed;
//     color: black;
    top:8.3%;
    padding-top: 5%;
    padding-bottom: 16%;
// 	bottom: 50%;
// 	left: 0;
// 	right: 0;
// 	margin: auto;
// background-color: rgb(214, 64, 64);
}
.touchInButton {
     font-size: 1rem;
     height: 3.625rem;
     display: inline-flex;
     align-items: center;
     justify-content: center;
     border: 2px solid transparent;
     text-decoration: none;
     background-color: black;
     padding: 0 3rem;
     border-radius: 2px;
     font-weight: 500;
     transition: all ease-in-out 250ms;
     position: relative;
     overflow: hidden;
     z-index: 0;
     font-family: "Avenir Next", Arial, sans-serif;
     cursor: pointer;
     color: white;
     margin-top: 2rem;
 }

 .touchInButton:before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 200%;
     height: 100%;
     background: white;
     -webkit-transform-origin: 0 0;
     transform-origin: 0 0;
     -webkit-transform: translateX(-200%) skewX(45deg);
     transform: translateX(-200%) skewX(45deg);
     transition: -webkit-transform ease-in-out 250ms;
     transition: transform ease-in-out 250ms;
     transition: transform ease-in-out 250ms, -webkit-transform ease-in-out 250ms;
     z-index: -1;
 }
 .touchInButton:focus:before,
 .touchInButton:hover:before {
     -webkit-transform: translateX(-40%) skewX(45deg);
     transform: translateX(-40%) skewX(45deg);
 }
 .touchInButton:focus,
 .touchInButton:hover {
     border-color: #111822;
     background-color: transparent;
     color: black;
 }
 


 .conModal h3{
    margin-left: 40%;
    margin-top: 4%;
    font-size: 1.9rem;
    margin-bottom: 3%;
}
.conModal h4{
    margin-left: 25%;
    margin-top: 4%;
    font-size: 1.9rem;
}
.conModal h5{
    margin-left: 5%;
    margin-top: 2%;
    font-size: 1.3rem;
    margin-bottom: 8%;
}
.conModal p{
    margin-left: 28%;
    margin-top: 2%;
    margin-bottom: 7%;
}
@media only screen and (max-width: 956px) {
       
    .conModal h4{
         margin-left: 5%;
    }
    .conModal p{
        margin-left: 8%;
    }

  }